var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Box, Stack } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfigItems from './navConfig';
import NavToggleButton from './NavToggleButton';
// ----------------------------------------------------------------------
export default function NavMini() {
    var navConfig = navConfigItems();
    return (_jsxs(Box, __assign({ component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD_MINI },
        } }, { children: [_jsx(NavToggleButton, { sx: {
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                } }), _jsxs(Stack, __assign({ sx: __assign({ pb: 2, height: 1, position: 'fixed', width: NAV.W_DASHBOARD_MINI, borderRight: function (theme) { return "dashed 1px ".concat(theme.palette.divider); } }, hideScrollbarX) }, { children: [_jsx(Logo, { sx: { mx: 'auto', my: 2 } }), _jsx(NavSectionMini, { data: navConfig })] }))] })));
}
